import React, { useContext, useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Store } from '../Store';
import { getError } from '../utils';
import { Col, Form, Button, ListGroup } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import MessageBox from '../components/MessageBox';
import SkeletonProductEditScreen from '../components/skeletons/SkeletonProductEditScreen';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, loading: false };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'UPDATE_REQUEST':
      return { ...state, loadingUpdate: true };
    case 'UPDATE_SUCCESS':
      return { ...state, loadingUpdate: false };
    case 'UPDATE_FAIL':
      return { ...state, loadingUpdate: false };
    case 'UPLOAD_REQUEST':
      return { ...state, loadingUpload: true, errorUpload: '' };
    case 'UPLOAD_SUCCESS':
      return {
        ...state,
        loadingUpload: false,
        errorUpload: '',
      };
    case 'UPLOAD_FAIL':
      return { ...state, loadingUpload: false, errorUpload: action.payload };

    default:
      return state;
  }
};
export default function ProductEditScreen() {
  const navigate = useNavigate();
  const params = useParams(); // /product/:id
  const { id: productId } = params;

  const { state } = useContext(Store);
  const { userInfo } = state;
  const [{ loading, error, loadingUpdate, loadingUpload }, dispatch] =
    useReducer(reducer, {
      loading: true,
      error: '',
    });

  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [price, setPrice] = useState('');
  const [salePrice, setSalePrice] = useState('');
  const [image, setImage] = useState('');
  const [imagePreview, setImagePreview] = useState('');
  const [images, setImages] = useState([]);
  const [additionalImagePreviews, setAdditionalImagePreviews] = useState([]);
  const [category, setCategory] = useState('');
  const [countInStock, setCountInStock] = useState('');
  const [style, setStyle] = useState('');
  const [finish, setFinish] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        const { data } = await axios.get(`/api/products/${productId}`);
        setName(data.name);
        setSlug(data.slug);
        setPrice(data.price);
        setSalePrice(data.salePrice || ''); // Fetch salePrice if available
        setImage(data.image);
        setImages(data.images);
        setAdditionalImagePreviews(data.images || []);
        setCategory(data.category);
        setCountInStock(data.countInStock);
        setStyle(data.style);
        setFinish(data.finish);
        setDescription(data.description);
        dispatch({ type: 'FETCH_SUCCESS' });
      } catch (err) {
        dispatch({
          type: 'FETCH_FAIL',
          payload: getError(err),
        });
      }
    };
    fetchData();
  }, [productId]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      dispatch({ type: 'UPDATE_REQUEST' });
      await axios.put(
        `/api/products/${productId}`,
        {
          _id: productId,
          name,
          slug,
          price,
          image,
          images,
          category,
          style,
          finish,
          countInStock,
          description,
          salePrice: salePrice ? salePrice : undefined, // Include salePrice if it exists
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      dispatch({
        type: 'UPDATE_SUCCESS',
      });
      toast.success('Product updated successfully', {
        autoClose: 1000, // Display success message for 1 second
      });
      setTimeout(() => {
        navigate('/admin/products');
      }, 1000); // Navigate to '/admin/products' after 1 second
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: 'UPDATE_FAIL' });
    }
  };

  const uploadFileHandler = async (e, forImages) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    try {
      dispatch({ type: 'UPLOAD_REQUEST' });
      const { data } = await axios.post('/api/upload', bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: `Bearer ${userInfo.token}`,
        },
      });
      console.log('Uploaded file path:', data);

      const fileUrl = data.url; // Use the URL field

      dispatch({ type: 'UPLOAD_SUCCESS' });

      if (forImages) {
        setImages([...images, fileUrl]); // Use only the URL
        setAdditionalImagePreviews([
          ...additionalImagePreviews,
          URL.createObjectURL(file),
        ]);
      } else {
        setImage(fileUrl); // Use only the URL
        setImagePreview(URL.createObjectURL(file));
      }

      toast.success('Image uploaded successfully. Click Update to apply it', {
        autoClose: 1000,
      });
    } catch (err) {
      toast.error(getError(err));
      dispatch({ type: 'UPLOAD_FAIL', payload: getError(err) });
    }
  };

  const deleteFileHandler = async (fileName, f) => {
    console.log(fileName, f);
    console.log(images);
    console.log(images.filter((x) => x !== fileName));
    setImages(images.filter((x) => x !== fileName));
    toast.success('Image removed successfully. click Update to apply it', {
      autoClose: 1000,
    });
  };

  return (
    <div className='small-screen'>
      <Helmet>
        <title>Edit Product ${productId}</title>
      </Helmet>
      <Col>
        <br />
        <h4 className='box'>Product Edit {productId}</h4>

        {loading ? (
          <SkeletonProductEditScreen delay={1000} />
        ) : error ? (
          <MessageBox variant='danger'>{error}</MessageBox>
        ) : (
          <Form onSubmit={submitHandler}>
            <Form.Group className='mb-3' controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className='mb-3' controlId='name'>
              <Form.Label>Price</Form.Label>
              <Form.Control
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className='mb-3' controlId='salePrice'>
              <Form.Label>Sale Price (Optional)</Form.Label>
              <Form.Control
                value={salePrice}
                onChange={(e) => setSalePrice(e.target.value)}
              />
            </Form.Group>

            <Form.Group className='mb-3' controlId='image'>
              <Form.Label>Main Image File</Form.Label>
              <Form.Control
                value={image}
                onChange={(e) => setImage(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className='mb-3' controlId='imageFile'>
              <Form.Label>Upload Image</Form.Label>
              <Form.Control
                type='file'
                onChange={(e) => uploadFileHandler(e, false)}
              />
              {loadingUpload && <SkeletonProductEditScreen delay={1000} />}
              {imagePreview && (
                <img
                  src={imagePreview}
                  alt='Main Preview'
                  style={{ width: '100px', height: '100px', marginTop: '10px' }}
                />
              )}
            </Form.Group>

            <Form.Group className='mb-3' controlId='additionalImageFile'>
              <Form.Label>Upload Additional Image</Form.Label>
              <Form.Control
                type='file'
                onChange={(e) => uploadFileHandler(e, true)}
              />
              {loadingUpload && <SkeletonProductEditScreen delay={1000} />}
            </Form.Group>

            <Form.Group className='mb-3' controlId='additionalImage'>
              <Form.Label>Additional Images</Form.Label>
              {additionalImagePreviews.length === 0 && (
                <MessageBox>No image</MessageBox>
              )}
              <ListGroup variant='flush'>
                {additionalImagePreviews.map((x, index) => (
                  <ListGroup.Item key={index}>
                    <img
                      src={x}
                      alt={`Additional Preview ${index}`}
                      style={{
                        width: '100px',
                        height: '100px',
                        marginRight: '10px',
                      }}
                    />
                    <Button
                      variant='light'
                      onClick={() => deleteFileHandler(images[index])}
                    >
                      <i className='fa fa-times-circle'></i>
                    </Button>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Form.Group>

            <Form.Group className='mb-3' controlId='category'>
              <Form.Label>Category</Form.Label>
              <Form.Control
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className='mb-3' controlId='style'>
              <Form.Label>Style</Form.Label>
              <Form.Control
                value={style}
                onChange={(e) => setStyle(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className='mb-3' controlId='finish'>
              <Form.Label>Finish</Form.Label>
              <Form.Control
                value={finish}
                onChange={(e) => setFinish(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className='mb-3' controlId='countInStock'>
              <Form.Label>Count In Stock</Form.Label>
              <Form.Control
                value={countInStock}
                onChange={(e) => setCountInStock(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group className='mb-3' controlId='description'>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as='textarea'
                rows={5} // 5 rows
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </Form.Group>

            <div className='mb-3'>
              <Button disabled={loadingUpdate} type='submit'>
                Update
              </Button>
              {loadingUpdate && <SkeletonProductEditScreen delay={1000} />}
            </div>
          </Form>
        )}
      </Col>
    </div>
  );
}
