import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

// COMPONENTS
import Header from './components/Header';
import BottomHeader from './components/BottomHeader';
import Footer from './components/Footer';
import BottomFooter from './components/BottomFooter';
import AdminRoute from './components/AdminRoute';
import ProtectedRoute from './components/ProtectedRoute';

// ADMIN SCREENS
import DashboardScreen from './screens/DashboardScreen';
import ProductListScreen from './screens/ProductListScreen';
import ProductEditScreen from './screens/ProductEditScreen';
import OrderListScreen from './screens/OrderListScreen';
import UserListScreen from './screens/UserListScreen';
import UserEditScreen from './screens/UserEditScreen';
import MessagesScreen from './screens/MessagesScreen';
import DesignSubmissionsScreen from './screens/DesignSubmissionScreen';
// ADMIN EDIT SCREENS
import AboutScreenContentEdit from './screens/AboutScreenContentEdit';
import FAQQuestionsScreenContentEdit from './screens/FAQQuestionsScreenContentEdit';
import DesignScreenContentEdit from './screens/DesignScreenContentEdit';
import HomeScreenContentEdit from './screens/HomeScreenContentEdit';
import ProductMagScreenContentEdit from './screens/ProductMagScreenContentEdit';

// SCREENS
import AboutScreen from './screens/AboutScreen';
import CartScreen from './screens/CartScreen'; // step 1
import ContactScreen from './screens/ContactScreen';
import DesignScreen from './screens/DesignScreen';
import FAQQuestionsScreen from './screens/FAQQuestionsScreen';
import GalleryScreen from './screens/GalleryScreen';
import HomeScreen from './screens/HomeScreen';
import JigScreen from './screens/JigScreen';
import PaymentMethod3Screen from './screens/PaymentMethod3Screen'; // step 3
import PlaceOrder4Screen from './screens/PlaceOrder4Screen'; // step 4
import ProductMagScreen from './screens/ProductMagScreen';
import ForgetPasswordScreen from './screens/ForgetPasswordScreen';
import SearchScreen from './screens/SearchScreen';
import ShippingAddress2Screen from './screens/ShippingAddress2Screen'; // step 2
import SigninScreen from './screens/SigninScreen';
import SignupScreen from './screens/SignupScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen';

// user protected pages
import ProfileScreen from './screens/ProfileScreen';
import OrderDetailsScreen from './screens/OrderDetailsScreen';
import OrderHistoryScreen from './screens/OrderHistoryScreen';

function App() {
  return (
    <BrowserRouter>
      <Header />
      <BottomHeader />
      <main className='mt-0'>
        <Routes>
          <Route path='/about' element={<AboutScreen />} />
          <Route path='/jig' element={<JigScreen />} />
          <Route path='/product/:slug' element={<ProductMagScreen />} />
          <Route path='/cart' element={<CartScreen />} />
          <Route path='/contact' element={<ContactScreen />} />
          <Route path='/design' element={<DesignScreen />} />
          <Route path='/FAQQuestions' element={<FAQQuestionsScreen />} />
          <Route path='/forget-password' element={<ForgetPasswordScreen />} />
          <Route path='/gallery' element={<GalleryScreen />} />
          <Route path='/payment' element={<PaymentMethod3Screen />} />
          <Route path='/search' element={<SearchScreen />} />
          <Route path='/shipping' element={<ShippingAddress2Screen />} />
          <Route path='/signin' element={<SigninScreen />} />
          <Route path='/signup' element={<SignupScreen />} />

          {/* Protected Routes */}
          <Route
            path='/profile'
            element={
              <ProtectedRoute>
                <ProfileScreen />
              </ProtectedRoute>
            }
          />
          <Route path='/placeorder' element={<PlaceOrder4Screen />} />
          <Route
            path='/order/:id'
            element={
              <ProtectedRoute>
                <OrderDetailsScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path='/orderhistory'
            element={
              <ProtectedRoute>
                <OrderHistoryScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path='/reset-password/:token'
            element={<ResetPasswordScreen />}
          />

          {/* Admin Routes */}
          <Route
            path='/admin/dashboard'
            element={
              <AdminRoute>
                <DashboardScreen />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/orders'
            element={
              <AdminRoute>
                <OrderListScreen />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/users'
            element={
              <AdminRoute>
                <UserListScreen />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/products'
            element={
              <AdminRoute>
                <ProductListScreen />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/messages'
            element={
              <AdminRoute>
                <MessagesScreen />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/product/:id'
            element={
              <AdminRoute>
                <ProductEditScreen />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/user/:id'
            element={
              <AdminRoute>
                <UserEditScreen />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/design-submissions'
            element={
              <AdminRoute>
                <DesignSubmissionsScreen />
              </AdminRoute>
            }
          />

          {/* Content Edit Screens */}
          <Route
            path='/admin/AboutScreenContentEdit'
            element={
              <AdminRoute>
                <AboutScreenContentEdit />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/FAQQuestionsScreenContentEdit'
            element={
              <AdminRoute>
                <FAQQuestionsScreenContentEdit />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/DesignScreenContentEdit'
            element={
              <AdminRoute>
                <DesignScreenContentEdit />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/homecontent'
            element={
              <AdminRoute>
                <HomeScreenContentEdit />
              </AdminRoute>
            }
          />
          <Route
            path='/admin/ProductMagScreenContentEdit'
            element={
              <AdminRoute>
                <ProductMagScreenContentEdit />
              </AdminRoute>
            }
          />
          <Route path='/' element={<HomeScreen />} />
        </Routes>
      </main>
      <Footer />
      <BottomFooter />
    </BrowserRouter>
  );
}

export default App;
