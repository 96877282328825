import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Form, Button, Row, Col, Table, Card } from 'react-bootstrap';
import DesignContentCard from '../components/DesignContentCard';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Store } from '../Store';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default function DesignScreen() {
  const [content, setContent] = useState([]);
  const [options, setOptions] = useState({
    capMaterial: [],
    clipType: [],
    nibType: [],
    section: [],
    bodyAccents: [],
  });
  const [designOptions, setDesignOptions] = useState({
    capMaterial: [],
    clipType: [],
    nibType: [],
    section: [],
    bodyAccents: [],
  });
  const [selectedPreview, setSelectedPreview] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { state } = useContext(Store);
  const { userInfo } = state;

  const [middleSectionData, setMiddleSectionData] = useState([]);
  const [loadingMiddleSection, setLoadingMiddleSection] = useState(true);
  const [errorMiddleSection, setErrorMiddleSection] = useState(false);

  const [customPenDetails, setCustomPenDetails] = useState({
    name: '',
    description: '',
  });
  const [uploadedImage, setUploadedImage] = useState(null);

  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, []);

  // Fetch content design choices top content (above the 1st HR)
  useEffect(() => {
    const fetchContent = async () => {
      try {
        const { data } = await axios.get('/api/design');
        setContent(data.sections); // Assuming data contains "sections"
      } catch (error) {
        console.error('Error fetching content:', error);
        toast.error('Failed to load content. Please try again.', {
          autoClose: 1000,
        });
      }
    };
    fetchContent();
  }, []);

  // middle section (between the HR's)
  useEffect(() => {
    const fetchMiddleSectionData = async () => {
      try {
        const { data } = await axios.get('/api/design/middle-section', {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        if (Array.isArray(data)) {
          setMiddleSectionData(data);
        } else {
          setMiddleSectionData([]);
          console.error('Expected an array but received:', data);
        }
        setErrorMiddleSection(false);
      } catch (error) {
        setErrorMiddleSection(true);
        console.error('Failed to fetch middle section data:', error);
      } finally {
        setLoadingMiddleSection(false);
      }
    };
    if (userInfo) fetchMiddleSectionData();
  }, [userInfo]);

  // Fetch options (below the 2nd HR)
  useEffect(() => {
    let isMounted = true; // Flag to track if the component is still mounted

    const fetchOptions = async () => {
      try {
        const response = await axios.get('/api/design/options');
        if (isMounted) {
          setOptions(response.data);
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching options:', error);
          toast.error('Failed to load options. Please try again.', {
            autoClose: 1000,
          });
        }
      }
    };

    fetchOptions();

    return () => {
      isMounted = false; // Cleanup function to set the flag to false when the component unmounts
    };
  }, []);

  const handleCheckboxChange = (e) => {
    const { name, value } = e.target;
    setDesignOptions((prevOptions) => {
      const currentOptions = prevOptions[name];
      const isChecked = currentOptions.includes(value);

      return {
        ...prevOptions,
        [name]: isChecked
          ? currentOptions.filter((item) => item !== value)
          : [...currentOptions, value],
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!customPenDetails.name || !customPenDetails.description) {
      toast.error('Please fill out all fields.');
      return;
    }

    if (!Object.values(designOptions).some((option) => option.length > 0)) {
      toast.error('Please make at least one selection for your custom pen.');
      return;
    }

    const formData = new FormData();
    formData.append('name', customPenDetails.name);
    formData.append('description', customPenDetails.description);

    if (uploadedImage) {
      formData.append('image', uploadedImage); // Only append image if it exists
    }

    formData.append('selections', JSON.stringify(designOptions));
    formData.append('userEmail', userInfo.email);

    setIsSubmitting(true);
    try {
      const { data } = await axios.post(
        '/api/design/design-submissions',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );

      setSelectedPreview({
        name: data.name,
        description: data.description,
        selections: data.selections,
        image: data.image,
      });

      toast.success('Custom pen submitted successfully!', {
        autoClose: 1000,
      });
      setCustomPenDetails({ name: '', description: '' });
      setUploadedImage(null);
      setDesignOptions({
        capMaterial: [],
        clipType: [],
        nibType: [],
        section: [],
        bodyAccents: [],
      });
    } catch (error) {
      console.error('Submission Error:', error.response || error.message);
      toast.error(
        error.response?.data?.message || 'Failed to submit custom pen.',
        {
          autoClose: 1000,
        }
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file && !['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
      toast.error('Invalid file type. Please upload a JPG, PNG, or GIF image.');
      setUploadedImage(null);
      return;
    }
    setUploadedImage(file);
  };

  const sendToAdmin = async () => {
    if (!userInfo) {
      toast.error('You must be logged in to submit a design.', {
        autoClose: 1000,
      });
      return;
    }

    if (isSubmitting) {
      toast.warning('Submission in progress. Please wait.');
      return;
    }

    if (!selectedPreview || selectedPreview.submitted) {
      toast.warning('This design has already been submitted.');
      return;
    }

    setIsSubmitting(true);

    try {
      const formData = new FormData();

      formData.append('userEmail', userInfo.email);
      formData.append('name', selectedPreview.name);
      formData.append('description', selectedPreview.description);

      const selections =
        typeof selectedPreview.selections === 'string'
          ? selectedPreview.selections
          : JSON.stringify(selectedPreview.selections);

      formData.append('selections', selections);

      if (selectedPreview.image) {
        const response = await fetch(selectedPreview.image);
        const blob = await response.blob();
        formData.append('image', blob, 'uploadedImage.jpg');
      }

      const { data } = await axios.post(
        '/api/design/design-submissions',
        formData,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      // Log or use the response data
      // console.log('Response from server:', data);
      toast.success('Submission confirmed: ' + data.message, {
        autoClose: 1000,
      });

      setSelectedPreview({ ...selectedPreview, submitted: true }); // Mark as submitted
    } catch (err) {
      console.error('Error submitting design:', err.response || err.message);
      toast.error(
        err.response?.data?.message || 'Failed to submit custom pen.',
        {
          autoClose: 1000,
        }
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  // Generate PDF
  const generatePDF = async () => {
    if (!selectedPreview) {
      toast.error('No selections to download.', { autoClose: 1000 });
      return;
    }

    try {
      const doc = new jsPDF();
      doc.setFontSize(16);
      doc.text('Exotic Wood Pen - Bespoke Pen Selection', 10, 10);

      // Add Pen Name and Description
      doc.setFontSize(12);
      doc.text(`Name: ${selectedPreview.name || 'N/A'}`, 10, 20);
      doc.text(`Description: ${selectedPreview.description || 'N/A'}`, 10, 30);

      // Ensure selections are parsed correctly
      const parsedSelections =
        typeof selectedPreview.selections === 'string'
          ? JSON.parse(selectedPreview.selections)
          : selectedPreview.selections;

      // Format selections for the table
      const tableData = Object.entries(parsedSelections || {}).map(
        ([key, values]) => [
          key.replace(/([A-Z])/g, ' $1').toUpperCase(), // Format key
          Array.isArray(values) ? values.join(', ') : String(values || 'N/A'),
        ]
      );

      // Add table for selections
      doc.autoTable({
        startY: 40,
        head: [['Category', 'Selection']],
        body: tableData,
      });

      // Add uploaded image (if available)
      if (selectedPreview.image) {
        const img = new Image();

        // Check if the image is a local blob (uploaded in the current session)
        if (selectedPreview.image.startsWith('blob:')) {
          img.src = selectedPreview.image;

          await new Promise((resolve, reject) => {
            img.onload = () => {
              doc.addImage(
                img,
                'JPEG',
                10,
                doc.autoTable.previous.finalY + 10,
                50,
                50
              );
              resolve();
            };
            img.onerror = reject;
          });
        } else {
          // If the image is a URL or file path
          const response = await fetch(selectedPreview.image);
          const blob = await response.blob();

          // Convert blob to Base64
          const reader = new FileReader();
          reader.readAsDataURL(blob);

          await new Promise((resolve, reject) => {
            reader.onloadend = () => {
              const base64data = reader.result;
              doc.addImage(
                base64data,
                'JPEG',
                10,
                doc.autoTable.previous.finalY + 10,
                50,
                50
              );
              resolve();
            };
            reader.onerror = reject;
          });
        }
      }

      // Save the PDF
      doc.save('pen-selection.pdf');
      toast.success('PDF downloaded successfully!', { autoClose: 1000 });
    } catch (error) {
      console.error('Error generating PDF:', error);
      toast.error('Failed to generate PDF. Please try again.', {
        autoClose: 1000,
      });
    }
  };

  return (
    <div className='content'>
      <Helmet>
        <title>Design</title>
      </Helmet>
      <br />
      {content.length > 0 ? (
        content.map((section, index) => (
          <DesignContentCard
            key={index}
            section={section}
            imagePosition={index % 2 === 0 ? 'left' : 'right'}
          />
        ))
      ) : (
        <p>Loading content...</p>
      )}
      <br />
      <hr className='line' />
      {loadingMiddleSection ? (
        <p>Loading middle section...</p>
      ) : errorMiddleSection ? (
        <p style={{ color: 'red' }}>
          Failed to load middle section. Please try again.
        </p>
      ) : (
        middleSectionData.map((section, index) => (
          <div key={index} className='box'>
            <Row>
              <Col md={12}>
                <h4 className='box'>{section.title}</h4>
              </Col>
              {section.items.map((item, idx) => (
                <Col xs={12} sm={6} md={4} lg={2} className='mb-3' key={idx}>
                  <Card className='text-center'>
                    <img
                      src={item.img}
                      className='img-responsive'
                      alt={`Pen design: ${item.description}`}
                      loading='lazy'
                    />
                    <p>{item.description}</p>
                  </Card>
                </Col>
              ))}
            </Row>
            <br />
          </div>
        ))
      )}
      <hr className='line' />
      <br />
      <Row>
        <Col md={6}>
          <Form className='box' onSubmit={handleSubmit}>
            <h4>Add Custom Pen Details</h4>
            <Form.Group controlId='formName' className='mb-3'>
              <Form.Label>Pen Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter the name of your custom pen'
                value={customPenDetails.name}
                onChange={(e) =>
                  setCustomPenDetails({
                    ...customPenDetails,
                    name: e.target.value,
                  })
                }
                required
              />
            </Form.Group>
            <Form.Group controlId='formDescription' className='mb-3'>
              <Form.Label>Pen Description</Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                placeholder='Enter a description for your custom pen'
                value={customPenDetails.description}
                onChange={(e) =>
                  setCustomPenDetails({
                    ...customPenDetails,
                    description: e.target.value,
                  })
                }
                required
              />
            </Form.Group>
            <Form.Group controlId='formImage' className='mb-3'>
              <Form.Label>* Optional * Upload Pen Image Concept</Form.Label>
              <Form.Control
                type='file'
                accept='image/*'
                onChange={handleImageUpload}
              />
              {uploadedImage && (
                <div className='mt-2'>
                  <p>Selected File: {uploadedImage.name}</p>
                  <img
                    src={URL.createObjectURL(uploadedImage)}
                    alt='Preview'
                    style={{ maxWidth: '100px', height: 'auto' }}
                  />
                </div>
              )}
            </Form.Group>
            <h4>Customize Your Pen</h4>
            {Object.entries(options).map(([category, values]) => (
              <div key={category}>
                <h5>{category.replace(/([A-Z])/g, ' $1').toUpperCase()}</h5>
                {values.map((value) => (
                  <label className='checkbox-label' key={value}>
                    <input
                      type='checkbox'
                      name={category}
                      value={value}
                      checked={designOptions[category].includes(value)}
                      onChange={handleCheckboxChange}
                    />
                    {value}
                  </label>
                ))}
              </div>
            ))}
            <Button variant='primary' type='submit' className='mt-2'>
              Save Changes
            </Button>
          </Form>
        </Col>
        <Col className='box' md={6}>
          <h2>Preview Your Submission</h2>
          {selectedPreview ? (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Details</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>{selectedPreview.name}</td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td>{selectedPreview.description}</td>
                </tr>
                <tr>
                  <td>Selections</td>
                  <td>
                    {Object.entries(
                      typeof selectedPreview.selections === 'string'
                        ? JSON.parse(selectedPreview.selections)
                        : selectedPreview.selections || {}
                    ).map(([key, values]) => (
                      <p key={key}>
                        <strong>
                          {key.replace(/([A-Z])/g, ' $1').toUpperCase()}:
                        </strong>{' '}
                        {Array.isArray(values)
                          ? values.join(', ')
                          : String(values || 'N/A')}
                      </p>
                    ))}
                  </td>
                </tr>
                <tr>
                  <td>Image</td>
                  <td>
                    {selectedPreview.image ? (
                      <img
                        src={`http://localhost:8000${selectedPreview.image}`} // Use full backend URL for images
                        alt='Uploaded Concept'
                        style={{ maxWidth: '100px', height: 'auto' }}
                      />
                    ) : (
                      'No image uploaded'
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          ) : (
            <p>No selections made yet.</p>
          )}
          {selectedPreview && (
            <>
              <Button
                variant='info'
                onClick={generatePDF}
                className='mt-2'
                disabled={!selectedPreview || isSubmitting}
              >
                Download PDF
              </Button>
              &nbsp;
              <Button
                variant='success'
                onClick={sendToAdmin}
                disabled={isSubmitting}
                className='mt-2'
              >
                {isSubmitting ? 'Submitting...' : 'Submit to Admin'}
              </Button>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
}
