import React, { useContext, useEffect, useReducer } from 'react';
import Chart from 'react-google-charts';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Store } from '../Store';
import { getError } from '../utils';
import MessageBox from '../components/MessageBox';
import { Row, Col, Card, Button } from 'react-bootstrap';
import SkeletonDashboardScreen from '../components/skeletons/SkeletonDashboardScreen';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        summary: action.payload,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default function DashboardScreen() {
  const [{ loading, summary, error }, dispatch] = useReducer(reducer, {
    loading: true,
    summary: { dailyOrders: [], productCategories: [], submissionTrends: [] },
    error: '',
  });
  const { state } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const { data: summaryData } = await axios.get('/api/orders/summary', {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });

        const { data: messagesData } = await axios.get('/api/messages');

        const { data: designSubmissionsData } = await axios.get(
          '/api/design/design-submissions',
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );

        if (isMounted) {
          dispatch({
            type: 'FETCH_SUCCESS',
            payload: {
              ...summaryData,
              messages: messagesData,
              designSubmissions: designSubmissionsData.submissions, // Pass submissions data
            },
          });
        }
      } catch (err) {
        if (isMounted) {
          dispatch({
            type: 'FETCH_FAIL',
            payload: getError(err),
          });
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [userInfo]);

  return (
    <div className='content'>
      <br />
      <h1 className='box'>Dashboard</h1>
      {loading ? (
        <SkeletonDashboardScreen />
      ) : error ? (
        <MessageBox variant='danger'>{error}</MessageBox>
      ) : (
        <>
          <Row className='mt-3'>
            {/* Cards */}
            <Col md={2}>
              <Card>
                <Card.Body>
                  <Card.Title>{summary.users?.[0]?.numUsers || 0}</Card.Title>
                  <Card.Text>
                    Users
                    <Button
                      variant='link'
                      onClick={() => navigate('/admin/users')}
                    >
                      View Details
                    </Button>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={2}>
              <Card>
                <Card.Body>
                  <Card.Title>{summary.orders?.[0]?.numOrders || 0}</Card.Title>
                  <Card.Text>
                    New Orders
                    <Button
                      variant='link'
                      onClick={() => navigate('/admin/orders')}
                    >
                      View Details
                    </Button>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={2}>
              <Card>
                <Card.Body>
                  <Card.Title>
                    ${summary.orders?.[0]?.totalSales?.toFixed(2) || 0}
                  </Card.Title>
                  <Card.Text>
                    Order Value
                    <Button
                      variant='link'
                      onClick={() => navigate('/admin/orders')}
                    >
                      View Details
                    </Button>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={2}>
              <Card>
                <Card.Body>
                  <Card.Title>{summary.messages?.length || 0}</Card.Title>
                  <Card.Text>
                    Messages
                    <Button
                      variant='link'
                      onClick={() => navigate('/admin/messages')}
                    >
                      View Details
                    </Button>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={2}>
              <Card>
                <Card.Body>
                  <Card.Title>
                    {summary.designSubmissions?.length || 0} {/* Show count */}
                  </Card.Title>
                  <Card.Text>
                    Design Submissions
                    <Button
                      variant='link'
                      onClick={() => navigate('/admin/design-submissions')}
                    >
                      View Details
                    </Button>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Charts */}
          <div className='my-3'>
            <h2>Sales</h2>
            {summary.dailyOrders?.length === 0 ? (
              <MessageBox>No Sale</MessageBox>
            ) : (
              <Chart
                width='100%'
                height='400px'
                chartType='AreaChart'
                loader={<div>Loading Chart...</div>}
                data={[
                  ['Date', 'Sales'],
                  ...(summary.dailyOrders || []).map((x) => [x._id, x.sales]),
                ]}
              ></Chart>
            )}
          </div>

          <div className='my-3'>
            <h2>Categories</h2>
            {summary.productCategories?.length === 0 ? (
              <MessageBox>No Category</MessageBox>
            ) : (
              <Chart
                width='100%'
                height='400px'
                chartType='PieChart'
                loader={<div>Loading Chart...</div>}
                data={[
                  ['Category', 'Products'],
                  ...(summary.productCategories || []).map((x) => [
                    x._id,
                    x.count,
                  ]),
                ]}
              ></Chart>
            )}
          </div>
        </>
      )}
      <br />
    </div>
  );
}
