import React, { useEffect, useState, useContext } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { Store } from '../Store';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function DesignScreenContentEdit() {
  const [content, setContent] = useState([]);
  const [designOptions, setDesignOptions] = useState({
    capMaterial: [],
    clipType: [],
    nibType: [],
    section: [],
    bodyAccents: [],
  });
  const [middleSectionData, setMiddleSectionData] = useState([]);
  const [dynamicInputs, setDynamicInputs] = useState({}); // Added dynamicInputs state
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMiddleSection, setLoadingMiddleSection] = useState(true);
  const [errorMiddleSection, setErrorMiddleSection] = useState(false);

  const { state } = useContext(Store);
  const { userInfo } = state;

  // Fetch all design data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get('/api/design', {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        setContent(data.sections || []);
        setDesignOptions(data.designOptions || {});
      } catch (error) {
        console.error(error);
        toast.error('Failed to fetch design content', {
          autoClose: 1000, // Duration in milliseconds (1 second)
        });
      }
    };

    const fetchMiddleSectionData = async () => {
      try {
        const { data } = await axios.get('/api/design/middle-section', {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        setMiddleSectionData(data || []);
        setErrorMiddleSection(false);
      } catch (error) {
        // console.log('Sending middleSection:', middleSectionData);
        setErrorMiddleSection(true);
      } finally {
        setLoadingMiddleSection(false);
      }
    };

    fetchData();
    fetchMiddleSectionData();
  }, [userInfo]);

  const [changedSections, setChangedSections] = useState(new Set());

  const markSectionAsChanged = (index) => {
    setChangedSections((prev) => new Set(prev).add(index));
  };

  const handleTitleChange = (sectionIndex, e) => {
    markSectionAsChanged(sectionIndex);
    const newContent = [...content];
    newContent[sectionIndex].title = e.target.value;
    setContent(newContent);
  };

  const handleParagraphChange = (sectionIndex, paragraphIndex, e) => {
    const newContent = [...content];
    newContent[sectionIndex].paragraphs[paragraphIndex] = e.target.value;
    setContent(newContent);
  };

  const handleAddParagraph = (sectionIndex) => {
    const newContent = [...content];
    newContent[sectionIndex].paragraphs.push('');
    setContent(newContent);
  };

  const handleDeleteParagraph = (sectionIndex, paragraphIndex) => {
    const newContent = [...content];
    newContent[sectionIndex].paragraphs.splice(paragraphIndex, 1);
    setContent(newContent);
  };

  const handleAddSection = () => {
    setContent([...content, { title: '', paragraphs: [''], images: [] }]);
  };

  const addDynamicInputField = (field) => {
    setDynamicInputs((prev) => ({
      ...prev,
      [field]: [...(prev[field] || []), ''],
    }));
  };

  const handleDynamicInputChange = (field, index, e) => {
    const newInputs = [...(dynamicInputs[field] || [])];
    newInputs[index] = e.target.value;
    setDynamicInputs((prev) => ({ ...prev, [field]: newInputs }));
  };

  const saveDynamicInputsToOptions = (field) => {
    setDesignOptions((prev) => {
      const updatedOptions = [
        ...new Set([...(prev[field] || []), ...(dynamicInputs[field] || [])]),
      ];
      return {
        ...prev,
        [field]: updatedOptions,
      };
    });
    setDynamicInputs((prev) => ({ ...prev, [field]: [] }));
  };

  const handleOptionRemove = (field, index) => {
    setDesignOptions((prev) => ({
      ...prev,
      [field]: prev[field].filter((_, i) => i !== index),
    }));
  };

  const handleImageNameChange = (sectionIndex, imageIndex, e) => {
    const newContent = [...content];
    newContent[sectionIndex].images[imageIndex].name = e.target.value;
    setContent(newContent);
  };

  const handleDeleteImage = (sectionIndex, imageIndex) => {
    const newContent = [...content];
    newContent[sectionIndex].images.splice(imageIndex, 1); // Remove the image
    setContent(newContent);
  };

  const handleDeleteSection = (sectionIndex) => {
    const newContent = [...content];
    newContent.splice(sectionIndex, 1); // Remove the section
    setContent(newContent);
  };

  const handleFileChange = async (sectionIndex, e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const { data } = await axios.post('/api/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${userInfo.token}`,
          },
        });

        const imageName = prompt('Enter a name for the image (optional):');
        const newContent = [...content];
        newContent[sectionIndex].images.push({
          url: data,
          name: imageName || '',
        });
        setContent(newContent);
        toast.success('Image uploaded successfully');
      } catch (error) {
        console.error(error);
        toast.error('Failed to upload image');
      }
    }
  };

  const validateSection = (section) => {
    if (!section.title.trim()) {
      toast.error('Section title cannot be empty');
      return false;
    }
    if (section.paragraphs.some((p) => !p.trim())) {
      toast.error('Paragraphs cannot be empty');
      return false;
    }
    if (section.images.length === 0) {
      toast.error('At least one image is required for the section');
      return false;
    }
    return true;
  };

  const handleSectionSubmit = async (sectionIndex) => {
    const section = content[sectionIndex];
    if (!validateSection(section)) return;

    try {
      await axios.put(
        `/api/design/section/${sectionIndex}`,
        { section },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      toast.success('Sections updated successfully!', {
        autoClose: 1000,
      });
    } catch (error) {
      console.error(error);
      toast.error('Failed to update section');
    }
  };

  // Handle title change for a middle section
  const handleMiddleTitleChange = (index, e) => {
    const newMiddleSection = [...middleSectionData];
    newMiddleSection[index].title = e.target.value;
    setMiddleSectionData(newMiddleSection);
  };

  // Handle item description change
  const handleMiddleItemChange = (sectionIndex, itemIndex, e) => {
    const newMiddleSection = [...middleSectionData];
    newMiddleSection[sectionIndex].items[itemIndex].description =
      e.target.value;
    setMiddleSectionData(newMiddleSection);
  };

  // Add a new middle section
  const handleAddMiddleSection = () => {
    setMiddleSectionData([
      ...middleSectionData,
      { title: '', items: [{ img: '', description: '' }] },
    ]);
  };

  // Add a new item to a middle section
  const handleAddMiddleItem = (sectionIndex) => {
    const newMiddleSection = [...middleSectionData];
    newMiddleSection[sectionIndex].items.push({ img: '', description: '' });
    setMiddleSectionData(newMiddleSection);
  };

  // Delete a middle section
  const handleDeleteMiddleSection = (sectionIndex) => {
    const newMiddleSection = [...middleSectionData];
    newMiddleSection.splice(sectionIndex, 1);
    setMiddleSectionData(newMiddleSection);
  };

  // Delete an item from a middle section
  const handleDeleteMiddleItem = (sectionIndex, itemIndex) => {
    const newMiddleSection = [...middleSectionData];
    newMiddleSection[sectionIndex].items.splice(itemIndex, 1);
    setMiddleSectionData(newMiddleSection);
  };

  // Save middle section to the backend
  const saveMiddleSection = async () => {
    setIsLoading(true);
    try {
      await axios.put(
        '/api/design/middle-section',
        { middleSection: middleSectionData },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      toast.success('Middle section updated successfully!', {
        autoClose: 1000, // Duration in milliseconds (1 second)
      });
    } catch (error) {
      console.error(error);
      toast.error('Failed to save middle section', {
        autoClose: 1000, // Duration in milliseconds (1 second)
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { data } = await axios.put(
        '/api/design',
        { sections: content, designOptions },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      setContent(data.sections);
      setDesignOptions(data.designOptions);
      toast.success('Design options updated successfully!', {
        autoClose: 1000,
      });
    } catch (error) {
      console.error(error);
      toast.error('Failed to update content', { autoClose: 1000 });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='content'>
      <Helmet>
        <title>Design Edit</title>
      </Helmet>
      <br />
      <h1 className='box'>Design Edit</h1>

      <Form onSubmit={handleSubmit}>
        {content.map((section, sectionIndex) => (
          <div
            key={sectionIndex}
            className={`box ${
              changedSections.has(sectionIndex) ? 'unsaved' : ''
            }`}
          >
            <Form.Group controlId={`formTitle${sectionIndex}`}>
              <Form.Label>Title {sectionIndex + 1}</Form.Label>
              <Form.Control
                type='text'
                value={section.title}
                onChange={(e) => handleTitleChange(sectionIndex, e)}
              />
            </Form.Group>

            {section.paragraphs.map((paragraph, paragraphIndex) => (
              <Form.Group
                controlId={`formParagraph${sectionIndex}-${paragraphIndex}`}
                key={paragraphIndex}
              >
                <Form.Label>Paragraph {paragraphIndex + 1}</Form.Label>
                <Form.Control
                  as='textarea'
                  rows={3}
                  value={paragraph}
                  onChange={(e) =>
                    handleParagraphChange(sectionIndex, paragraphIndex, e)
                  }
                />
                <Button
                  variant='danger'
                  onClick={() =>
                    handleDeleteParagraph(sectionIndex, paragraphIndex)
                  }
                  className='mt-2'
                >
                  Delete Paragraph
                </Button>
              </Form.Group>
            ))}

            <Button
              onClick={() => handleAddParagraph(sectionIndex)}
              className='mt-2 mr-2'
            >
              Add Paragraph
            </Button>

            {/* Image Upload Field */}
            <Form.Group controlId={`formImageUpload${sectionIndex}`}>
              <Form.Label>Upload Images</Form.Label>
              <Form.Control
                type='file'
                accept='image/*'
                onChange={(e) => handleFileChange(sectionIndex, e)}
              />
              <div className='mt-2'>
                {section.images &&
                  section.images.map((image, imageIndex) => (
                    <div key={imageIndex} className='mt-2'>
                      <img
                        src={image.url}
                        alt={`Preview ${imageIndex + 1}`}
                        style={{ maxWidth: '100px', height: 'auto' }}
                        loading='lazy'
                      />
                      <Form.Control
                        type='text'
                        placeholder='Enter image name'
                        value={image.name || ''}
                        onChange={(e) =>
                          handleImageNameChange(sectionIndex, imageIndex, e)
                        }
                        className='mt-2'
                      />
                      <Button
                        variant='danger'
                        onClick={() =>
                          handleDeleteImage(sectionIndex, imageIndex)
                        }
                        size='sm'
                        className='mt-2'
                      >
                        Delete Image
                      </Button>
                    </div>
                  ))}
              </div>
              <Button
                variant='primary'
                onClick={() => handleSectionSubmit(sectionIndex)}
                className='mt-2'
              >
                Save Changes for This Section
              </Button>
              &nbsp;
              <Button
                variant='danger'
                onClick={() => handleDeleteSection(sectionIndex)}
                className='mt-2'
              >
                Delete Section
              </Button>
            </Form.Group>
          </div>
        ))}
        <Button
          variant='success'
          onClick={handleAddSection}
          className='mt-2 mr-2'
        >
          Add Section
        </Button>
        &nbsp;
        <Button variant='primary' type='submit' className='mt-2'>
          Save Changes
        </Button>
      </Form>

      <hr className='line' />
      {/* **************************** Middle Section *****************************/}

      {/* Bespoke Pen Options */}
      <h1 className='box'>Edit Middle Section</h1>
      {loadingMiddleSection ? (
        <p>Loading middle section...</p>
      ) : errorMiddleSection ? (
        <p>Error loading middle section. Please try again later.</p>
      ) : (
        <Form>
          {middleSectionData.map((section, index) => (
            <div key={index} className='box'>
              <Form.Group controlId={`middleTitle${index}`}>
                <Form.Label>Section Title</Form.Label>
                <Form.Control
                  type='text'
                  value={section.title}
                  onChange={(e) => handleMiddleTitleChange(index, e)}
                />
              </Form.Group>
              {section.items.map((item, itemIndex) => (
                <div key={itemIndex}>
                  <Row>
                    <Col md={6}>
                      <Form.Group
                        controlId={`middleImage${index}-${itemIndex}`}
                      >
                        <Form.Label>Upload Image</Form.Label>
                        <Form.Control
                          type='file'
                          accept='image/*'
                          onChange={async (e) => {
                            const file = e.target.files[0];
                            if (file) {
                              const formData = new FormData();
                              formData.append('file', file);
                              try {
                                const { data } = await axios.post(
                                  '/api/upload',
                                  formData,
                                  {
                                    headers: {
                                      'Content-Type': 'multipart/form-data',
                                      Authorization: `Bearer ${userInfo.token}`,
                                    },
                                  }
                                );
                                const newMiddleSection = [...middleSectionData];
                                newMiddleSection[index].items[itemIndex].img =
                                  data;
                                setMiddleSectionData(newMiddleSection);
                                toast.success('Image uploaded successfully!', {
                                  autoClose: 1000,
                                });
                              } catch (error) {
                                console.error(error);
                                toast.error('Failed to upload image', {
                                  autoClose: 1000,
                                });
                              }
                            }
                          }}
                        />
                        {item.img && (
                          <div className='mt-2'>
                            <img
                              src={item.img}
                              alt={`Preview ${itemIndex + 1}`}
                              style={{ maxWidth: '100px', height: 'auto' }}
                              loading='lazy'
                            />
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        controlId={`middleDescription${index}-${itemIndex}`}
                      >
                        <Form.Label>Item Description</Form.Label>
                        <Form.Control
                          type='text'
                          value={item.description}
                          onChange={(e) =>
                            handleMiddleItemChange(index, itemIndex, e)
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button
                    variant='danger'
                    onClick={() => handleDeleteMiddleItem(index, itemIndex)}
                  >
                    Delete Item
                  </Button>
                  <hr />
                </div>
              ))}
              <Button
                onClick={() => handleAddMiddleItem(index)}
                className='mt-2'
              >
                Add Item
              </Button>
              <Button
                variant='danger'
                onClick={() => handleDeleteMiddleSection(index)}
                className='mt-2 ml-2'
              >
                Delete Section
              </Button>
              <hr />
            </div>
          ))}
          <Button
            onClick={handleAddMiddleSection}
            className='mt-2'
            variant='success'
          >
            Add Middle Section
          </Button>
          <Button
            onClick={saveMiddleSection}
            className='mt-2 ml-2'
            variant='primary'
          >
            Save Middle Section
          </Button>
        </Form>
      )}

      <hr className='line' />
      {/* **************************** Bottom Section *****************************/}

      <h2 className='box'>
        All pens are standard 14mm triple start thread unless segmented then
        they are double start thread
      </h2>
      <Form onSubmit={handleSubmit}>
        <h4>Bespoke Design Pen Options</h4>
        {Object.keys(designOptions).map((field) => (
          <Form.Group key={field} className='box'>
            <Form.Label>{`Customize Your ${field}`}</Form.Label>
            <ul>
              {designOptions[field]?.map((option, index) => (
                <li key={index} className='customize-option-item'>
                  <span style={{ marginRight: '10px' }}>{option}</span>
                  <Button
                    variant='danger'
                    size='sm'
                    onClick={() => handleOptionRemove(field, index)}
                    className='remove-button'
                  >
                    Remove
                  </Button>
                </li>
              ))}
            </ul>
            <div>
              {(dynamicInputs[field] || []).map((value, index) => (
                <Form.Control
                  key={index}
                  type='text'
                  placeholder={`Enter ${field}`}
                  value={value}
                  className='mb-2'
                  onChange={(e) => handleDynamicInputChange(field, index, e)}
                />
              ))}
            </div>
            <Button
              variant='success'
              size='sm'
              onClick={() => addDynamicInputField(field)}
            >
              Add New Input
            </Button>
            &nbsp;
            <Button
              variant='primary'
              size='sm'
              onClick={() => saveDynamicInputsToOptions(field)}
            >
              Save Inputs
            </Button>
          </Form.Group>
        ))}

        <Button
          variant='primary'
          type='submit'
          className='mt-2'
          disabled={isLoading}
        >
          {isLoading ? 'Saving...' : 'Save Changes'}
        </Button>
      </Form>
      <br />
    </div>
  );
}
