import React, { useEffect, useState, useContext, useReducer } from 'react';
import axios from 'axios';
import { Table, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import MessageBox from '../components/MessageBox';
import SkeletonDesignSubmissionsScreen from '../components/skeletons/SkeletonDesignSubmissionsScreen';
import { Store } from '../Store';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        totalSubmissions: action.payload.totalSubmissions,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default function DesignSubmissionsScreen() {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [{ totalSubmissions }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const { data } = await axios.get('/api/design/design-submissions', {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });

        console.log('API Response:', data); // Debug the API response

        if (
          !data ||
          !Array.isArray(data.submissions) ||
          typeof data.totalSubmissions !== 'number'
        ) {
          throw new Error('Unexpected response format');
        }

        setSubmissions(data.submissions); // Update submissions state
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        console.error(err.message || 'Failed to fetch submissions');
        setError(err.message || 'Failed to fetch submissions');
        dispatch({ type: 'FETCH_FAIL', payload: err.message });
      } finally {
        setLoading(false);
      }
    };

    fetchSubmissions();
  }, [userInfo, dispatch]);

  const deleteSubmissionHandler = async (id) => {
    if (window.confirm('Are you sure to delete this submission?')) {
      try {
        await axios.delete(`/api/design/design-submissions/${id}`, {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        });
        toast.success('Submission deleted successfully', {
          autoClose: 1000,
        });
        setSubmissions((prev) => prev.filter((sub) => sub._id !== id)); // Update local state
      } catch (err) {
        toast.error('Failed to delete submission', {
          autoClose: 1000,
        });
      }
    }
  };

  return (
    <div className='content'>
      <Helmet>
        <title>Design Submissions</title>
      </Helmet>
      <br />
      <h4 className='box'>
        Design Submissions (
        {totalSubmissions !== undefined ? totalSubmissions : 'Loading...'} )
      </h4>
      {loading ? (
        <SkeletonDesignSubmissionsScreen />
      ) : error ? (
        <MessageBox variant='danger'>{error}</MessageBox>
      ) : submissions.length === 0 ? (
        <MessageBox>No submissions found</MessageBox>
      ) : (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Submission ID</th>
              <th>Date</th>
              <th>User</th>
              <th>Pen Name</th>
              <th>Pen Description</th>
              <th>Selections</th>
              <th>Image</th> {/* New column for images */}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {submissions.map((submission) => (
              <tr key={submission._id}>
                <td>{submission._id}</td>
                <td>
                  {new Date(submission.createdAt).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                  })}
                </td>
                <td>{submission.userEmail}</td>
                <td>{submission.name}</td>
                <td>{submission.description}</td>
                <td>
                  <ul>
                    {(() => {
                      let parsedSelections;

                      try {
                        // Parse `selections` only if it is a string
                        parsedSelections =
                          typeof submission.selections === 'string'
                            ? JSON.parse(submission.selections)
                            : submission.selections;
                      } catch (err) {
                        console.error('Error parsing selections:', err.message);
                        parsedSelections = {}; // Default to empty object in case of error
                      }

                      return Object.entries(parsedSelections).map(
                        ([key, values]) => (
                          <li key={key}>
                            <strong>{key.replace(/([A-Z])/g, ' $1')}:</strong>{' '}
                            {Array.isArray(values) ? values.join(', ') : values}
                          </li>
                        )
                      );
                    })()}
                  </ul>
                </td>

                <td>
                  {submission.image ? (
                    <img
                      src={submission.image}
                      alt='Uploaded Pen'
                      style={{ maxWidth: '100px', height: 'auto' }}
                    />
                  ) : (
                    'No Image'
                  )}
                </td>
                <td>
                  <Button
                    variant='danger'
                    onClick={() => deleteSubmissionHandler(submission._id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <br />
    </div>
  );
}
